// import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';

import { getPreviewLinks } from 'reducers/previewLinks';
import { getUserInputMacros } from 'selectors/macros';
import { setMacro } from 'reducers/currentMacroConfig';
import { Input/*, SubmitButton*/ } from 'components/FormComponents';
// import { SpinnerOverlay } from 'components/Spinner';
import StandardModal from 'modals/StandardModal';
import { closeModal } from 'reducers/modal';

const MacroSettingsModal = ({ options }) => {
	const dispatch = useDispatch();

	const {
		macros,
		macroConfig,
		socialAccountId,
		templateId,
	} = useSelector(state => ({
		macros: getUserInputMacros(state),
		macroConfig: state.currentMacroConfig.config,
		socialAccountId: state.currentSocialAccount.socialAccount?.social_account_id,
		templateId: state.currentTemplate.template?.idcreative_template,
	}));

	const onMacroChange = (name, val) => {
		dispatch(setMacro(name, val));
	}

	const updateLinks = () => {
		if (socialAccountId && templateId) {
			dispatch(getPreviewLinks(socialAccountId, templateId));
		}
		dispatch(closeModal());
	}

	return (
		<StandardModal
			title="Configure Macros"
			size="lg"
			closeOnOutsideClick={false}
			onConfirm={updateLinks}
			confirmLabel="Update Previews"
		>
			<div>
				{/*<SpinnerOverlay isLoading={isLoading} />*/}
				{(!macros || macros.length === 0) && <div>This template doesn't have any macros</div>}
				<Form>
					{macros.map(macro => (
						<Input key={macro.macro} label={macro.name} value={macroConfig[macro.macro]} name={macro.macro} onChange={(e) => onMacroChange(macro.macro, e.target.value)} />
					))}
				</Form>
			</div>
		</StandardModal>
	);
};

export default MacroSettingsModal;
