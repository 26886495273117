// import Card from 'react-bootstrap/Card';
// import Placeholder from 'react-bootstrap/Placeholder';
import MissingMedia from 'components/socialPosts/MissingMedia';

import './InstagramPost.css';

// const INSTA_DIMENSIONS = 300;

function Media({ post }) {
	if (post.media_type === 'VIDEO') {
		if (!post.media_url) {
			return <MissingMedia text="Video missing" isSquare />;
		}
		return (
			<video controls>
				<source src={post.media_url} type="video/mp4" />
			</video>
		);
	} else if (post.media_type === 'IMAGE') {
		if (!post.media_url) {
			return <MissingMedia text="Image missing" isSquare />;
		}
		return (
			<img src={post.media_url} alt={post.media_url} />
		);
	}
	
	return <MissingMedia text={`Unknown media type: ${post.media_type}`} isSquare />;
}


export const InstagramPost = ({ post }) => {
	// console.log('InstagramPost', post);
	return (
		<div className="instagram-post">

			<div className="instagram-media-wrapper">
				<Media post={post} />
			</div>

			<div className="instagram-post-bottom">
				{/*<div className="circle" />*/}
				<div className="instagram-post-caption">
					<div>
						{post.caption}
					</div>
					<div>
						@{post.username}
					</div>
				</div>
			</div>

		</div>
	);
}

export default InstagramPost;