import { getTeamSocialAccounts } from 'selectors/socialAccounts';

export const SET_CURRENT_SOCIAL_ACCOUNT = 'SET_CURRENT_SOCIAL_ACCOUNT';

const initialState = {
	socialAccount: null,
};

export const setSelectedSocialAccount = (socialAccountId) => (dispatch, getState) => {
	if (!socialAccountId) {
		setCurrentSocialAccount(null);
	}
	const state = getState();
	const socialAccounts = getTeamSocialAccounts(state) || [];
	let socialAccount = socialAccounts.find(account => account.social_account_id === parseInt(socialAccountId)) || null;
	dispatch(setCurrentSocialAccount(socialAccount));
};

const setCurrentSocialAccount = (socialAccount) => ({
	type: SET_CURRENT_SOCIAL_ACCOUNT,
	payload: {
		socialAccount: socialAccount,
	},
});


const currentSocialAccountReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_SOCIAL_ACCOUNT:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default currentSocialAccountReducer;
