import { useDispatch } from 'react-redux';
// import { saveFacebookToken, saveInstagramToken } from 'reducers/teams';
import { saveFacebookTokenToUser, saveInstagramTokenToUser } from 'reducers/user';
import { instaRedirectUrl } from 'utils/authUtils';
import { useHistory } from "react-router-dom";

/* this page is for receiving the auth redirect from facebook/instagram */

function SocialAuth({ socialPlatform }) {
	const dispatch = useDispatch();
	const history = useHistory();

	console.log('socialPlatform', socialPlatform);

	if (socialPlatform === 'facebook') {
		const authData = getHashAuthFromUrl();
		// let team_uuid = authData?.state?.team_uuid;

		if (authData) {
			delete authData.state;
			console.log('authData', authData);

			dispatch(saveFacebookTokenToUser(authData));
			// history.replace(`/teams/${team_uuid}/social-accounts`); //remove auth stuff from url
			history.replace(`/user/social-accounts`); //remove auth stuff from url
		}
	} else if (socialPlatform === 'instagram') {
		const authData = getQueryAuthFromUrl();
		// let team_uuid = authData?.state?.team_uuid;

		if (authData) {
			delete authData.state;
			authData.redirect_uri = instaRedirectUrl;
			console.log('authData', authData);
			dispatch(saveInstagramTokenToUser(authData));
			// history.replace(`/teams/${team_uuid}/social-accounts`); //remove auth stuff from url
			history.replace(`/user/social-accounts`); //remove auth stuff from url
		}
	}

	return <div>{socialPlatform} auth page</div>;
}

export default SocialAuth;

//todo: this makes a lot of assumptions about the structure of the redirect we get from aws
const getHashAuthFromUrl = () => {
	console.log(window.location);
	let hash = window.location.hash;
	console.log('hash', hash);
	if (hash) {
		hash = hash.substring(1);
		let stringParts = hash.split('&');
		let authObject = {};
		stringParts.forEach(part => {
			let varParts = part.split('=');
			if (varParts[0] === 'state') {
				let stateString = decodeURIComponent(varParts[1]);
				let stateObj = JSON.parse(stateString);
				console.log('stateObj', stateObj);
				authObject[varParts[0]] = stateObj;
			} else {
				authObject[varParts[0]] = varParts[1];
			}
		});
		return authObject;
	}
	return null;
}

//todo: this makes a lot of assumptions about the structure of the redirect we get from aws
const getQueryAuthFromUrl = () => {
	console.log(window.location);
	let query = window.location.search;
	console.log('query', query);
	if (query) {
		query = query.substring(1);
		let stringParts = query.split('&');
		let authObject = {};
		stringParts.forEach(part => {
			let varParts = part.split('=');
			if (varParts[0] === 'state') {
				let stateString = decodeURIComponent(varParts[1]);
				let stateObj = JSON.parse(stateString);
				console.log('stateObj', stateObj);
				authObject[varParts[0]] = stateObj;
			} else {
				authObject[varParts[0]] = varParts[1];
			}
		});
		return authObject;
	}
	return null;
}

/*
example facebook hash:
#access_token=EAAUSGsJznb8BAJfZASfWAMF9oEhJK0ErOs5W9UQZB1Q7w91yIG1SmFsqFdvTHpmqjKU3VMZCgaQxGPZBsnzNwyDqGoi2UtgC6chQKc1Eh2wa7gYKAsmLcZBPuGbgpMZCGr8YEMm77ZBoFYRASOo8wjEUb2k5dzBsZCWrMN7z4okgTrK9uuDZAJ4lcLASVNqqErMw3IkYxnQcZC2wZDZD
&data_access_expiration_time=1669756986
&expires_in=5814
&long_lived_token=EAAUSGsJznb8BABhrWSgMmaLa0EtZBVZA2hW0FnB59hBx3b3A4X0AuAesjDHd3JMPZCBfzJzBvccoppllh6EzoJZBWHDFgYZAZA3969a4opBSYmNOoZChpcBuQzyfrAISDimm2k3xs9xPkdLwbxmdsEXi7d6hz8UZA2AJ1dqO5iNekfXKGpjGblRT
&state=%7B"team_uuid"%3A"5spyzrr"%7D
*/

//http://localhost:8082/add-facebook-pages?#access_token=EAAUSGsJznb8BAJfZASfWAMF9oEhJK0ErOs5W9UQZB1Q7w91yIG1SmFsqFdvTHpmqjKU3VMZCgaQxGPZBsnzNwyDqGoi2UtgC6chQKc1Eh2wa7gYKAsmLcZBPuGbgpMZCGr8YEMm77ZBoFYRASOo8wjEUb2k5dzBsZCWrMN7z4okgTrK9uuDZAJ4lcLASVNqqErMw3IkYxnQcZC2wZDZD&data_access_expiration_time=1669756986&expires_in=5814&long_lived_token=EAAUSGsJznb8BABhrWSgMmaLa0EtZBVZA2hW0FnB59hBx3b3A4X0AuAesjDHd3JMPZCBfzJzBvccoppllh6EzoJZBWHDFgYZAZA3969a4opBSYmNOoZChpcBuQzyfrAISDimm2k3xs9xPkdLwbxmdsEXi7d6hz8UZA2AJ1dqO5iNekfXKGpjGblRT&state=%7B%22team_uuid%22%3A%225spyzrr%22%7D
