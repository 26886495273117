// import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';

import { logoutUrl } from 'utils/authUtils';
import { logout } from 'reducers/user';
import { Input/*, SubmitButton*/ } from 'components/FormComponents';
// import { SpinnerOverlay } from 'components/Spinner';
import StandardModal from 'modals/StandardModal';

const UserSettingsModal = ({ options }) => {
	const dispatch = useDispatch();

	const {
		// isLoading,
		userData,
	} = useSelector(state => ({
		// isLoading: state.user.isLoading,
		userData: state.user.userData || {},
	}));


	const handleLogout = () => {
		dispatch(logout());
		window.location.href = logoutUrl;
	}


	return (
		<StandardModal
			title="Account Settings"
			size="lg"
			closeOnOutsideClick={false}
		>
			<div>
				{/*<SpinnerOverlay isLoading={isLoading} />*/}
				<Form>
					<Input label="Name" disabled value={userData.name || ''} name="name" />
					<Input label="Email" disabled value={userData.email || ''} name="email" />
					{/*<SubmitButton onSubmit={onSubmit} />*/}
				</Form>
				<button key="logout" className="btn btn-danger" onClick={handleLogout}>Sign Out</button>
			</div>
		</StandardModal>
	);
};

export default UserSettingsModal;
