
//todo: toggle this by env
// let redirectUrl = 'https://socialseen.io/';

let redirectUrl = 'https://app.socialseen.io/';

// console.log('env', process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
	redirectUrl = 'http://localhost:8082/';
}

export const fbRedirectUrl = `${redirectUrl}add-facebook-pages`;
export const instaRedirectUrl = `${redirectUrl}add-instagram-account`

/*
https://auth.socialseen.io/login?client_id=4gkuchpj16b088vipgjopbfgq0&&response_type=token&scope=email+openid&redirect_uri=https://socialseen.io/

https://auth.socialseen.io/logout?client_id=4gkuchpj16b088vipgjopbfgq0&&response_type=token&scope=email+openid&redirect_uri=https://socialseen.io/
*/
export const loginUrl = `https://auth.socialseen.io/login?client_id=4gkuchpj16b088vipgjopbfgq0&&response_type=token&scope=email+openid&redirect_uri=${redirectUrl}`;
export const logoutUrl = `https://auth.socialseen.io/logout?client_id=4gkuchpj16b088vipgjopbfgq0&&response_type=token&scope=email+openid&redirect_uri=${redirectUrl}`;

// export const facebookUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=1427281024359871&redirect_uri=https%3A%2F%2Fapi.socialseen.io%2Fv1%2Fteams%2Fadd-facebook-pages&scope=public_profile,email,pages_show_list,instagram_basic,pages_read_engagement,pages_read_user_content,pages_manage_engagement,instagram_manage_insights&response_type=token&state={team_uuid}`


/*
https://www.facebook.com/v14.0/dialog/oauth?client_id=1427281024359871&redirect_uri=https%3A%2F%2Fapi.socialseen.io%2Fv1%2Fteams%2Fadd-facebook-pages&scope=public_profile,email,pages_show_list,instagram_basic,pages_read_engagement,pages_read_user_content,pages_manage_engagement,instagram_manage_insights&response_type=token&state=${encodedState}
*/
export const getFacebookAuthUrl = (team_uuid) => {
	let state = {
		// redirect_uri: redirectUrl,
		team_uuid: team_uuid,
	};
	let fbRedirectUrlEncoded = encodeURIComponent(fbRedirectUrl);
	// let fbRedirectUrl = `${redirectUrl}add-facebook-pages`;
	let stringifiedState = JSON.stringify(state);
	let encodedState = encodeURIComponent(stringifiedState);
	return `https://www.facebook.com/v14.0/dialog/oauth?client_id=1427281024359871&redirect_uri=${fbRedirectUrlEncoded}&scope=public_profile,email,pages_show_list,instagram_basic,pages_read_engagement,pages_read_user_content,pages_manage_engagement,instagram_manage_insights&response_type=token&state=${encodedState}`;
}

/*
https://api.instagram.com/oauth/authorize?client_id=1075723973331639&redirect_uri=https%3A%2F%2Fapp.socialseen.io%2Fadd-instagram-account&scope=user_profile,user_media&response_type=code&state=%7B%22team_uuid%22%3A%22tbwdgwr%22%7D
*/
export const getInstagramAuthUrl = (team_uuid) => {
	let state = {
		// redirect_uri: redirectUrl,
		team_uuid: team_uuid,
	};

	let instaRedirectUrlEncoded = encodeURIComponent(instaRedirectUrl);
	let stringifiedState = JSON.stringify(state);
	let encodedState = encodeURIComponent(stringifiedState);
	return `https://api.instagram.com/oauth/authorize?client_id=1075723973331639&redirect_uri=${instaRedirectUrlEncoded}&scope=user_profile,user_media&response_type=code&state=${encodedState}`;
}