import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreatives } from 'reducers/creatives';
import { SpinnerOverlay } from 'components/Spinner';

const AdLibrary = () => {
	const dispatch = useDispatch();
	const {
		isLoading,
		currentTeam,
		creatives,
	} = useSelector(state => ({
		isLoading: state.currentTeam.isLoading || state.creatives.isLoading,
		currentTeam: state.currentTeam?.data || {},
		creatives: state.creatives?.data?.creative || [],
	}));

	useEffect(() => {
		if (currentTeam?.team_uuid) {
			dispatch(getCreatives(currentTeam.team_uuid));	
		}
	}, [currentTeam, dispatch]);

	return (
		<div className="">
			<SpinnerOverlay isLoading={isLoading} />
			<div className="m-3">
				{creatives.length === 0 ? "There's nothing here" : null}
				<div className="pb-5">
					{creatives?.map((creative, i) => (
						<div key={`${currentTeam?.team_uuid}-${creative.creative_uuid}-${i}`} className="d-inline-block m-3">
							<iframe
								
								className=""
								title={creative.creative_name}
								src={`https://api.socialseen.io/v1${creative.preview_url}`}
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default AdLibrary;
