import { get } from 'data/services/apiService';
import { showToast } from 'reducers/toast';

export const GET_SOCIAL_FEED_REQUEST = 'GET_SOCIAL_FEED_REQUEST';
export const GET_SOCIAL_FEED_SUCCESS = 'GET_SOCIAL_FEED_SUCCESS';
export const GET_SOCIAL_FEED_ERROR = 'GET_SOCIAL_FEED_ERROR';


const initialState = {
	isLoading: false,
	socialAccountId: null,
	data: null,
	error: null,
};

export const getFeed = (socialAccountId, { limit=20, after=0 }={}) => (dispatch, getState) => {
	let url = `https://api.socialseen.io/v1/social-accounts/${socialAccountId}/feed?limit=${limit}`;

	if (after) {
		url += `&after=${after}`;
	}

	dispatch(getFeedRequest(socialAccountId));

	return get({
		url,
		onSuccess: (result) => {
			return dispatch(getFeedSuccess(result));
		},
		onError: (error) => {
			dispatch(showToast({ message: 'Something went wrong loading your feed.', color: 'danger', duration: 5000 }));
			return dispatch(getFeedError(error));
		},
	});
};

const getFeedRequest = (socialAccountId) => ({
		type: GET_SOCIAL_FEED_REQUEST,
		payload: {
			socialAccountId,
			isLoading: true,
		},
});

const getFeedSuccess = (data) => ({
		type: GET_SOCIAL_FEED_SUCCESS,
		payload: {
			isLoading: false,
			data,
			error: null,
		},
});

const getFeedError = (error) => ({
		type: GET_SOCIAL_FEED_ERROR,
		payload: {
			isLoading: false,
			data: null,
			error: error,
		},
});


const socialFeedReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SOCIAL_FEED_REQUEST:
		case GET_SOCIAL_FEED_SUCCESS:
		case GET_SOCIAL_FEED_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default socialFeedReducer;
