import StandardModal from 'modals/StandardModal';

const TestModal = ({ options }) => {
	console.log('options', options)
	return (
		<StandardModal
			title="Test Modal"
			size="md"
			closeOnOutsideClick
		>
			<div>
				Test modal content
			</div>
			<div className="m-3">
				{options.customText}
			</div>
		</StandardModal>
	);
};

export default TestModal;
