import { loginUrl } from 'utils/authUtils';

let dispatch = null;
let getState = null;

export const initApiService = (dispatchRef, getStateRef) => {
	if (!dispatch || !getState) {
		dispatch = dispatchRef;
		getState = getStateRef;
	}
}

export const get = ({ url, onSuccess, onError }) => {
	let authToken = getState().user.awsAuthData?.id_token;
	let requestParams = {};

	if (authToken) {
		requestParams.headers = {
			'authorization': `Bearer ${authToken}`,
		};
	}

	return fetch(new Request(url, requestParams)).then((result) => {
		if (result.status === 401) {
			window.location.href = loginUrl;
		} else if (result.ok) {
			return result.json().then(data => onSuccess(data));
		}
		return result.json().then(data => onError(data));
	});
};

export const post = ({ url, data, onSuccess, onError }) => {
	let authToken = getState().user.awsAuthData?.id_token;
	let requestParams = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json, text/plain, */*',
		},
	};

	if (authToken) {
		requestParams.headers.authorization = `Bearer ${authToken}`;
	}

	if (data) {
		requestParams.body = JSON.stringify(data);
	}

	return fetch(url, requestParams).then((result) => {
		if (result.status === 401) {
			window.location.href = loginUrl;
		} else if (result.ok) {
			return result.json().then(data => onSuccess(data));
		}
		return result.json().then(data => onError(data));
	});
};

export const del = ({ url, data, onSuccess, onError }) => {
	let authToken = getState().user.awsAuthData?.id_token;
	let requestParams = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'Accept': 'application/json, text/plain, */*',
		},
	};

	if (authToken) {
		requestParams.headers.authorization = `Bearer ${authToken}`;
	}

	if (data) {
		requestParams.body = JSON.stringify(data);
	}

	return fetch(url, requestParams).then((result) => {
		if (result.status === 401) {
			window.location.href = loginUrl;
		} else if (result.ok) {
			return result.json().then(data => onSuccess(data));
		}
		return result.json().then(data => onError(data));
	});
};
