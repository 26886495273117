import { Link } from "react-router-dom";

const HeaderLogo = ({ color='pink' }) => {
	if (color !== 'pink' && color !== 'green') {
		color = 'pink';
	}

	return (
		<span className="navbar-brand">
			<Link to="/">
				<img className="header-logo" src={`/logo_oneline_${color}.svg`} alt="socialseen" />
			</Link>
		</span>
	);
};

export default HeaderLogo;