import './SocialIcon.css';

export const SocialIcon = ({ platform, className, autoColor }) => {
	let fb = <i className={`social-icon bi bi-facebook ${className || ''} ${autoColor ? 'autocolor' : ''}`} />;
	let insta = <i className={`social-icon bi bi-instagram ${className || ''} ${autoColor ? 'autocolor' : ''}`} />;

	if (/instagram/gi.test(platform)) {
		return insta
	} else if (/facebook/gi.test(platform)) {
		return fb;
	}
	return null;
}

export default SocialIcon;