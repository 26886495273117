import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from "react-router-dom";

import {
	setAwsAuth,
	getUser
} from 'reducers/user';
import { getTemplates } from 'reducers/templates';
import { DEFAULT_TEMPLATE_ID, setSelectedTemplate } from 'reducers/currentTemplate';
import AppLayout from 'layouts/AppLayout';
import TeamLayout from 'layouts/TeamLayout';

import MainPage from 'pages/MainPage';
import SocialAuth from 'pages/social/SocialAuth';
import Teams from 'pages/teams/Teams';
import TeamSettings from 'pages/teams/TeamSettings';
import TeamUsers from 'pages/teams/TeamUsers';
import AdBuilder from 'pages/adBuilder/AdBuilder';
import AdLibrary from 'pages/creativeLibrary/AdLibrary';
import TeamSocialAccounts from 'pages/social/TeamSocialAccounts';
import UserSocialAccounts from 'pages/social/UserSocialAccounts';
import UserSettings from 'pages/user/UserSettings';

import './App.css';
import './theme.css';
	/* eslint-disable react-hooks/exhaustive-deps */
function App() {
	const dispatch = useDispatch();
	const history = useHistory();
	const authData = getAuthFromUrl();
	const {
		isUserLoaded,
	} = useSelector(state => ({
		isUserLoaded: !!state.user.userData?.username,
	}));

	if (authData) {
		dispatch(setAwsAuth(authData));
		// history.replace('/'); //remove auth stuff from url
		history.replace('/teams');
	}

	useEffect(() => {
		//todo: this could fail if getAuthFromUrl() didn't work
		dispatch(getUser());
	}, []);

	useEffect(() => {
		if (isUserLoaded) {
			dispatch(getTemplates()).then((res) => {
				dispatch(setSelectedTemplate(DEFAULT_TEMPLATE_ID));
			});
		}
	}, [isUserLoaded]);


	return (
		<Switch>
			<Route path="/teams/:teamId/ad-builder">
				<TeamLayout><AdBuilder /></TeamLayout>
			</Route>
			<Route path="/teams/:teamId/ad-library">
				<TeamLayout><AdLibrary /></TeamLayout>
			</Route>
			<Route path="/teams/:teamId/social-accounts">
				<TeamLayout><TeamSocialAccounts /></TeamLayout>
			</Route>
			<Route path="/teams/:teamId/team-settings">
				<TeamLayout><TeamSettings /></TeamLayout>
			</Route>
			<Route path="/teams/:teamId/users">
				<TeamLayout><TeamUsers /></TeamLayout>
			</Route>
			<Route path="/teams">
				<AppLayout><Teams /></AppLayout>
			</Route>
			<Route path="/user-settings">
				<AppLayout><UserSettings /></AppLayout>
			</Route>
			<Route path="/user/social-accounts">
				<AppLayout><UserSocialAccounts /></AppLayout>
			</Route>
			<Route path="/add-instagram-account">
				<AppLayout><SocialAuth socialPlatform="instagram" /></AppLayout>
			</Route>
			<Route path="/add-facebook-pages">
				<AppLayout><SocialAuth socialPlatform="facebook" /></AppLayout>
			</Route>
			<Route path="/">
				<AppLayout><MainPage /></AppLayout>
			</Route>
		</Switch>
	);
}

export default App;

//todo: this makes a lot of assumptions about the structure of the redirect we get from aws
const getAuthFromUrl = () => {

	//checking that the path is root is to ensure that this only runs for aws cognito login redirect
	//facebook auth redirect has a very similar url hash structure
	if (window.location.pathname === '/') { 
		let hash = window.location.hash;
		console.log('hash', hash);
		if (hash) {
			hash = hash.substring(1);
			let stringParts = hash.split('&');
			let authObject = {};
			stringParts.forEach(part => {
				let varParts = part.split('=');
				authObject[varParts[0]] = varParts[1];
			});
			return authObject;
		}
		return null;
	}
	return null;		
}
