import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';

import { loginUrl, logoutUrl } from 'utils/authUtils';
import { logout } from 'reducers/user';

import "./AccountDropdown.css";

const AccountDropdown = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		isSignedIn,
		currentTeam,
	} = useSelector(state => ({
		isSignedIn: !!state.user.awsAuthData,
		currentTeam: state.currentTeam.data || {},
	}));

	const handleLogout = () => {
		dispatch(logout());
		window.location.href = logoutUrl;
	}

	const navTo = (url) => {
		history.push(url);
	}

	const signedInDropdown = (
		<div className="account-dropdown">
			<NavDropdown title={<i className={`bi bi-person-circle`} />}>
				<NavDropdown.Item onClick={() => navTo("/teams")}>Teams</NavDropdown.Item>
				<NavDropdown.Item onClick={() => navTo(`/teams/${currentTeam.team_uuid}/team-settings`)}>Team Settings</NavDropdown.Item>
				<NavDropdown.Item onClick={() => navTo("/user/social-accounts")}>Social Accounts</NavDropdown.Item>
				<NavDropdown.Item onClick={() => navTo("/user-settings")}>User Settings</NavDropdown.Item>
				<NavDropdown.Item onClick={handleLogout}>Sign Out</NavDropdown.Item>
			</NavDropdown>
		</div>
	);

	const signedOutDropdown = (
		<div className="account-dropdown">
			<NavDropdown title={<i className={`bi bi-person-circle`} />}>
				<NavDropdown.Item href={loginUrl}>Sign In</NavDropdown.Item>
				<NavDropdown.Item href={loginUrl}>Sign Up</NavDropdown.Item>
			</NavDropdown>
		</div>
	);

	return isSignedIn ? signedInDropdown : signedOutDropdown;
};

export default AccountDropdown;
