// import Card from 'react-bootstrap/Card';
// import Placeholder from 'react-bootstrap/Placeholder';
import MissingMedia from 'components/socialPosts/MissingMedia';

import './FacebookPost.css';

// const INSTA_DIMENSIONS = 300;

function Media({ post }) {
	if (post?.full_picture) {
		return <img src={post.full_picture} alt={post.full_picture} />;
	} else {
		return <MissingMedia text="No media" isSquare />;
	}
	// return <MissingMedia text={`Unknown media type: ${post.media_type}`} isSquare />;
}


export const FacebookPost = ({ post }) => {
	return (
		<div className="instagram-post">

			<div className="instagram-media-wrapper">
				<Media post={post} />
			</div>

			<div className="instagram-post-bottom">
				{/*<div className="circle" />*/}
				<div className="instagram-post-caption">
					<div>
						{post.status_type}
					</div>
					<div>
						@{post?.['from']?.name}
					</div>
				</div>
			</div>

		</div>
	);
}

export default FacebookPost;