import { SET_AWS_AUTH, LOGOUT, initialState } from 'reducers/user';

const authMiddleware = ({ getState, dispatch }) => {
	return next => action => {
		if (action.type === SET_AWS_AUTH) {
			localStorage.setItem('awsAuthData', JSON.stringify(action.payload.awsAuthData));
		} else if (action.type === LOGOUT) {
			localStorage.removeItem('awsAuthData');
		} 
		return next(action);
	}
}

export const getSessionState = () => {
	let awsAuthDataString = localStorage.getItem('awsAuthData');

	if (awsAuthDataString) {
		//todo: try catch parse and clear localstorage if its in a bad state
		let awsAuthData = JSON.parse(awsAuthDataString);
		let user = Object.assign({}, initialState, {
			awsAuthData,
		});
		return { user };
	} else {
		return {};
	}
}


export default authMiddleware;