import { get, post } from 'data/services/apiService';
// import { loginUrl } from 'utils/authUtils';
import { showToast } from 'reducers/toast';
import { getTeamsSuccess } from 'reducers/teams';

export const SET_AWS_AUTH = 'SET_AWS_AUTH';
export const LOGOUT = 'LOGOUT';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const initialState = {
	awsAuthData: null,
	userData: null,
	isLoading: false,
	error: null,
};

export const setAwsAuth = (awsAuthData) => ({
	type: SET_AWS_AUTH,
	payload: {
		awsAuthData,
	}
});

export const logout = () => ({
	type: LOGOUT,
	payload: {
		awsAuthData: null,
		userData: null,
	}
});

export const getUser = (params) => (dispatch) => {
	const url = `https://api.socialseen.io/v1/user`;

	dispatch(getUserRequest());
	return get({
		url,
		onSuccess: (result) => {
			dispatch(getTeamsSuccess(result.teams));
			return dispatch(getUserSuccess(result));
		},
		onError: (error) => {
			// console.log('error', error)
			dispatch(showToast({ message: 'Error loading user data', color: 'danger' }));
			return dispatch(getUserError(error));
		},
	});
};


export const saveFacebookTokenToUser = (facebookAuthObject) => (dispatch) => {
	if (!facebookAuthObject) {
		console.log('error saving facebook token. facebookAuthObject', facebookAuthObject);
		return;
	}
	dispatch(updateUserRequest());

	return post({
		url: `https://api.socialseen.io/v1/user/add-facebook-pages`,
		data: facebookAuthObject,
		onSuccess: (result) => {
			console.log('result', result);
			return dispatch(updateUserSuccess(result));
		},
		onError: (error) => {
			console.log('error', error);
			return dispatch(updateUserError(error));
		},
	});
};

export const saveInstagramTokenToUser = (instagramAuthObject) => (dispatch) => {
	if (!instagramAuthObject) {
		console.log('error saving facebook token. instagramAuthObject', instagramAuthObject);
		return;
	}
	dispatch(updateUserRequest());

	return post({
		url: `https://api.socialseen.io/v1/user/add-instagram-account`,
		data: instagramAuthObject,
		onSuccess: (result) => {
			console.log('result', result);
			return dispatch(updateUserSuccess(result));
		},
		onError: (error) => {
			console.log('error', error);
			return dispatch(updateUserError(error));
		},
	});
};


const getUserRequest = () => ({
		type: GET_USER_REQUEST,
		payload: {
			isLoading: true,
		},
});

const getUserSuccess = (data) => ({
		type: GET_USER_SUCCESS,
		payload: {
			isLoading: false,
			userData: data,
			error: null,
		},
});

const getUserError = (error) => ({
		type: GET_USER_ERROR,
		payload: {
			isLoading: false,
			userData: null,
			error: error,
		},
});

const updateUserRequest = () => ({
		type: UPDATE_USER_REQUEST,
		payload: {
			isLoading: true,
		},
});

const updateUserSuccess = (data) => ({
		type: UPDATE_USER_SUCCESS,
		payload: {
			isLoading: false,
			userData: data,
			error: null,
		},
});

const updateUserError = (error) => ({
		type: UPDATE_USER_ERROR,
		payload: {
			isLoading: false,
			userData: null,
			error: error,
		},
});


const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_REQUEST:
		case GET_USER_SUCCESS:
		case GET_USER_ERROR:
		case UPDATE_USER_REQUEST:
		case UPDATE_USER_SUCCESS:
		case UPDATE_USER_ERROR:
		case LOGOUT:
		case SET_AWS_AUTH:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
