import { getUserInputMacros } from 'selectors/macros';

export const SET_CURRENT_MACRO_CONFIG = 'SET_CURRENT_MACRO_CONFIG';

const initialState = {
	templateId: null,
	config: {},
};

export const setMacro = (macroName, value) => (dispatch, getState) => {
	const state = getState();
	const templateId = state.currentMacroConfig.templateId;
	const config = Object.assign({}, state.currentMacroConfig.config);

	config[macroName] = value;
	dispatch(setMacroConfig(templateId, config));
};

export const resetMacroConfig = () => (dispatch, getState) => {
	const state = getState();
	const currentTemplate = state.currentTemplate?.template;

	if (!currentTemplate) {
		dispatch(setMacroConfig(null, {}));
	} else {
		let macros = getUserInputMacros(state);
		let config = {};
		macros.forEach(macro => {
			//todo: handle non-text macros
			config[macro.macro] = '';
		});
		dispatch(setMacroConfig(currentTemplate.idcreative_template, config));
	}
};


const setMacroConfig = (templateId, config) => ({
	type: SET_CURRENT_MACRO_CONFIG,
	payload: {
		templateId,
		config,
	},
});


const currentTemplateReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_MACRO_CONFIG:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default currentTemplateReducer;
