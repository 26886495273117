// import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SpinnerOverlay } from 'components/Spinner';
import { hasTeamSocials, getTeamSocialAccounts } from 'selectors/socialAccounts';
import SocialIcon from 'components/SocialIcon';
// import SocialConnectButton from 'components/SocialConnectButton';

import { openModal } from 'reducers/modal';
import { MODAL_TYPES } from 'view/ModalManager';


const TeamSocialAccounts = () => {
	const dispatch = useDispatch();
	const {
		isLoading,
		currentTeam,
		noSocials,
		socialAccounts,
	} = useSelector(state => ({
		isLoading: state.currentTeam.isLoading || state.currentTeam.isSaving,
		currentTeam: state.currentTeam.data || {},
		noSocials: !hasTeamSocials(state),
		socialAccounts: getTeamSocialAccounts(state),
	}));

	const openSocialConnectModal = () => {
		dispatch(openModal(MODAL_TYPES.SOCIAL_CONNECT, {}));
		// dispatch(openModal(MODAL_TYPES.SOCIAL_WIZARD, {}));
	}

	return (
		<div className="">
			<SpinnerOverlay isLoading={isLoading} />
			<div className="m-3">
				<h4 className="mb-3">Team Social Accounts</h4>
				<div>Connected social accounts for <strong>{currentTeam?.team_name}</strong></div>
			</div>
			<div className="m-3">
				<button className="btn btn-primary btn-sm" onClick={openSocialConnectModal}>Connect Social Accounts</button>
			</div>
{/*			<div className="m-3">
				<SocialConnectButton team_uuid={currentTeam.team_uuid} platform="facebook" />
			</div>*/}
			<div className="m-3">
				<table className="table">
					<thead>
						<tr>
							<th />
							<th>@handle</th>
							<th>Account Name</th>
							<th>Platform</th>
							<th>Account ID</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{noSocials && <tr><td colSpan="5" className="text-center">No social accounts connected to this team</td></tr>}
						{socialAccounts.map(account => (
							<tr key={account.external_account_id}>
								<td><SocialIcon platform={account.platform_type} autoColor /></td>
								<td>@{account.external_handle}</td>
								<td>{account.external_name}</td>
								<td>{account.platform_name}</td>
								<td>{account.external_account_id}</td>
								<td><button className="btn btn-sm btn-danger" disabled onClick={() => {}}>Disconnect</button></td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TeamSocialAccounts;
