// import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
// import Form from 'react-bootstrap/Form';
import { createStripePortalSession } from 'reducers/currentTeam';
// import { checkout } from 'data/services/paymentService';
// import { showToast } from 'reducers/toast';

// import { Input, SubmitButton } from 'components/FormComponents';

// import './TeamSettings.css';


const TeamSettings = ({ options }) => {
	const dispatch = useDispatch();
	const {
		currentTeam,
		team_uuid,
		email,
		hasActiveAccount,
	} = useSelector(state => ({
		currentTeam: state.currentTeam.data || {},
		team_uuid: state.currentTeam.data?.team_uuid,
		email: state.user.userData?.email,
		hasActiveAccount: !!state.currentTeam?.data?.stripe_customer_id,
	}));

	// const [formData, setFormData] = useState({
	// 	teamName: currentTeam?.team_name || '',
	// });
	// const [isCheckingOut, setIsCheckingOut] = useState(false);

	// useEffect(() => {
	// 	// if (currentTeam?.team_name) {
	// 	// 	setFormData(Object.assign({}, formData, {
	// 	// 		teamName: currentTeam.team_name
	// 	// 	}));
	// 	// }
	// }, [currentTeam, formData])

	// const onSubmit = () => {
	// 	console.log('data', formData);
	// };

	// const onInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	let data = Object.assign({}, formData);
	// 	data[name] = value;
	// 	setFormData(data);
	// };

	// const handleCheckoutError = () => {
	// 	setIsCheckingOut(false);
	// 	dispatch(showToast({
	// 		message: 'Something went wrong with your account activation request, please contact support.',
	// 		color: 'danger',
	// 		duration: 5000,
	// 	}));
	// }

	const activatePayment = () => {
		window.location.href = `https://socialseen.io/pricing/#team_uuid=${team_uuid}&email=${email}`
		// setIsCheckingOut(true);
		// checkout(currentTeam.team_uuid).then((res) => {
		// 	if (res.checkout_url) {
		// 		window.location.href = res.checkout_url;
		// 	} else {
		// 		handleCheckoutError()
		// 	}
		// }, handleCheckoutError);
	}

	const managePayments = () => {
		dispatch(createStripePortalSession()).then(res => {
			window.open(res.portal_url, '_blank')
		});
	}

	let paymentButton = <button className="btn btn-success mb-4" onClick={activatePayment}>Activate your Account!</button>;

	if (hasActiveAccount) {
		paymentButton = <button className="btn btn-success mb-4" onClick={managePayments}>Manage payments</button>
	}

	return (
		<div className="form-page">
			<h4 className="mb-4">Team Settings</h4>
			<div className="mb-4">Team name: {currentTeam?.team_name}</div>
			<div className="mb-4">
				<Link to={`/teams/${team_uuid}/users`}>Manage collaborators</Link>
			</div>
			{paymentButton}
{/*			<Form>
				<Input label="Team name" placeholder="Team name" onChange={onInputChange} value={formData.teamName} name="teamName" />
				<div className="add-social-placeholder has-border" >
					Add Social Accounts
				</div>
				<SubmitButton onSubmit={onSubmit} />
			</Form>*/}
		</div>
	);
};

export default TeamSettings;
