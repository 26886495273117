import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { getCurrentTeam } from 'reducers/currentTeam';
// import { getTeams } from 'reducers/teams';

import { SpinnerOverlay } from 'components/Spinner';
import AppLayout from 'layouts/AppLayout';
// import TeamSidebar from 'layouts/TeamSidebar';

import './TeamLayout.css';

const TeamLayout = ({ children }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { teamId } = useParams();
	const {
		isLoading,
		isLoadingTeams,
		// currentTeam,
		teams,
		// teamsError,
	} = useSelector(state => ({
		isLoading: state.teams.isLoading || state.currentTeam.isLoading || state.teams.isSaving,
		isLoadingTeams: state.teams.isLoading,
		// currentTeam: state.currentTeam.data || {},
		teams: state.teams.teamList,
		// teamsError: state.teams.error,
	}));

	useEffect(() => {
		if (!teamId || teamId === 'undefined') {
			history.replace(`/teams`);
		} else if (!isLoadingTeams && teamId && teams?.length) {
			dispatch(getCurrentTeam(teamId));
		}
	}, [teamId, teams, isLoadingTeams, dispatch, history]);

	// useEffect(() => {
	// 	if (!isLoadingTeams && !teams.length && !teamsError) {
	// 		dispatch(getTeams());
	// 	}
	// }, [teams, isLoadingTeams, teamsError, dispatch]);

	return (
		<AppLayout>
			<div className="team-layout-wrapper">
				<SpinnerOverlay isLoading={isLoading} />
				{/*<TeamSidebar />*/}
				<div className="page-container team-page-container">
					{children}
				</div>
			</div>
		</AppLayout>
	);
};

export default TeamLayout;
