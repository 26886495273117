import { useSelector } from 'react-redux';
import AppHeader from 'components/appHeader/AppHeader';
import { SpinnerOverlay } from 'components/Spinner';

import './AppLayout.css'

const AppLayout = ({ children }) => {
	const {
		isLoading,
	} = useSelector(state => ({
		isLoading: state.user.isLoading,
	}));
	return (
		<div className="app-layout">
			<AppHeader />
			<SpinnerOverlay isLoading={isLoading} />
			<div className="app-content-container">
				{children}
			</div>
		</div>
	);
};

export default AppLayout;
