import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';

import { createTeam } from 'reducers/teams';
// import { closeModal } from 'reducers/modal';
import StandardModal from 'modals/StandardModal';
import { Input } from 'components/FormComponents';

import './CreateTeamModal.css';


const CreateTeamModal = ({ options }) => {
	const dispatch = useDispatch();
	const {
		isSaving,
	} = useSelector(state => ({
		isSaving: state.teams.isSaving,
	}));
	const [formData, setFormData] = useState({
		teamName: '',
	});

	const onConfirm = () => {
		console.log('data', formData);
		dispatch(createTeam(formData));
	};

	const onInputChange = (e) => {
		const { name, value } = e.target;
		let data = Object.assign({}, formData);
		data[name] = value;
		setFormData(data);
	};

	return (
		<StandardModal
			title="Create Team"
			size="md"
			closeOnOutsideClick={false}
			onConfirm={onConfirm}
			confirmLabel="Create"
			closeLabel="Cancel"
			isSaving={isSaving}
		>
			<div>
				<Form>
					<Input label="Team name" placeholder="Team name" onChange={onInputChange} value={formData.teamName} name="teamName" />
					<div className="add-social-placeholder has-border" >
						Add Social Accounts
					</div>
					<div className="add-email-placeholder has-border" >
						Add Collaborators
					</div>
				</Form>
			</div>
		</StandardModal>
	);
};

export default CreateTeamModal;
