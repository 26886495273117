import { Link } from "react-router-dom";

import "./HeaderNavLink.css";


const HeaderNavLink = ({ icon, label, url }) => {
	if (!url || !icon || !label) {
		return null;
	}

	const active = url => window.location.pathname === url ? 'is-active' : '';

	return (
		<div className={`header-nav-item ${active(url)} mx-3`} key={url}>
			<Link className="header-nav-link" to={url}>
				<i className={`bi bi-${icon}`} />
				<span className="label">{label}</span>
			</Link>
		</div>
	);
};

export default HeaderNavLink;
