import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

import authMiddleware, { getSessionState } from 'data/middleware/authMiddleware';
import { initApiService } from 'data/services/apiService';

import creatives from 'reducers/creatives';
import currentMacroConfig from 'reducers/currentMacroConfig';
import currentSocialAccount from 'reducers/currentSocialAccount';
import currentTeam from 'reducers/currentTeam';
import currentTemplate from 'reducers/currentTemplate';
import modal from 'reducers/modal';
import previewLinks from 'reducers/previewLinks';
import socialFeed from 'reducers/socialFeed';
import teams from 'reducers/teams';
import templates from 'reducers/templates';
import toast from 'reducers/toast';
import user from 'reducers/user';

export const history = createBrowserHistory();


let reducers = {
	creatives,
	currentMacroConfig,
	currentSocialAccount,
	currentTeam,
	currentTemplate,
	modal,
	previewLinks,
	socialFeed,
	teams,
	templates,
	toast,
	user,
};

export const actions = {};

const initialState = Object.assign({},
	getSessionState(),
);


const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	...reducers,
});

//hook up redux dev tools extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	createRootReducer(history),
	initialState,
	composeEnhancers(applyMiddleware(
		routerMiddleware(history),
		authMiddleware,
		thunk
	))
);

initApiService(store.dispatch, store.getState);

window.store = () => store.getState(); //debug
window.actions = () => actions; //debug

export default store;
