// import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SpinnerOverlay } from 'components/Spinner';
import { hasUserSocials, getUserSocialAccounts } from 'selectors/socialAccounts';
import SocialIcon from 'components/SocialIcon';
import SocialConnectButton from 'components/SocialConnectButton';


const UserSocialAccounts = () => {
	const {
		isLoading,
		noSocials,
		socialAccounts,
		userData,
	} = useSelector(state => ({
		isLoading: state.user.isLoading,
		noSocials: !hasUserSocials(state),
		socialAccounts: getUserSocialAccounts(state),
		userData: state.user.userData || {},
	}));

	return (
		<div className="">
			<SpinnerOverlay isLoading={isLoading} />
			<div className="m-3">
				<h4 className="mb-3">User Social Accounts</h4>
				<div>Authorized social accounts for <strong>{userData?.name} ({userData?.email})</strong></div>
			</div>
			<div className="m-3">
				<SocialConnectButton platform="facebook" />
			</div>
			<div className="m-3">
				<table className="table">
					<thead>
						<tr>
							<th />
							<th>@handle</th>
							<th>Account Name</th>
							<th>Platform</th>
							<th>Account ID</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{noSocials && <tr><td colSpan="5" className="text-center">No social accounts connected</td></tr>}
						{socialAccounts.map(account => (
							<tr key={account.external_account_id}>
								<td><SocialIcon platform={account.platform_type} autoColor /></td>
								<td>@{account.external_handle}</td>
								<td>{account.external_name}</td>
								<td>{account.platform_name}</td>
								<td>{account.external_account_id}</td>
								<td><button className="btn btn-sm btn-danger" disabled onClick={() => {}}>Remove</button></td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default UserSocialAccounts;
