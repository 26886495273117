// import { useState } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";

import { Input/*, SubmitButton*/ } from 'components/FormComponents';
import { SpinnerOverlay } from 'components/Spinner';

// import './UserSettings.css';


const UserSettings = ({ options }) => {
	const {
		isLoading,
		userData,
	} = useSelector(state => ({
		isLoading: state.user.isLoading,
		userData: state.user.userData || {},
	}));

	// const [formData, setFormData] = useState({
	// 	name: '',
	// 	email: '',
	// });

	// const onSubmit = () => {
	// 	console.log('data', formData);
	// };

	// const onInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	let data = Object.assign({}, formData);
	// 	data[name] = value;
	// 	setFormData(data);
	// };

	return (
		<div className="form-page">
			<SpinnerOverlay isLoading={isLoading} />
			<h4 className="mb-4">User Settings</h4>
			<div className="mb-3"><Link className="btn btn-sm btn-primary" to="/user/social-accounts">Linked Social Accounts</Link></div>
			<Form>
				<Input label="Name" disabled value={userData.name || ''} name="name" />
				<Input label="Email" disabled value={userData.email || ''} name="email" />
				{/*<SubmitButton onSubmit={onSubmit} />*/}
			</Form>
		</div>
	);

	// return (
	// 	<div className="form-page">
	// 		<h4 className="mb-4">User Settings</h4>
	// 		<Form>
	// 			<Input label="Name" placeholder="Name" onChange={onInputChange} value={formData.name} name="name" />
	// 			<Input label="Email" placeholder="Email" onChange={onInputChange} value={formData.email} name="email" />
	// 			{/*<SubmitButton onSubmit={onSubmit} />*/}
	// 		</Form>
	// 	</div>
	// );
};

export default UserSettings;
