import { get, post } from 'data/services/apiService';
import { showToast } from 'reducers/toast';

export const GET_CURRENT_TEAM_REQUEST = 'GET_CURRENT_TEAM_REQUEST';
export const GET_CURRENT_TEAM_SUCCESS = 'GET_CURRENT_TEAM_SUCCESS';
export const GET_CURRENT_TEAM_ERROR = 'GET_CURRENT_TEAM_ERROR';

export const CREATE_STRIPE_PORTAL_SESSION = 'CREATE_STRIPE_PORTAL_SESSION';

const initialState = {
	isLoading: false,
	data: null,
	error: null,
};

export const createStripePortalSession = (teamId) => (dispatch, getState) => {
	let state = getState();
	let team_uuid = state.currentTeam.data?.team_uuid;
	return post({
		url: `https://api.socialseen.io/v1/teams/${team_uuid}/create-portal-session`,
		onSuccess: (result) => {
			return result;
		},
		onError: (error) => {
			dispatch(showToast({ message: 'Error creating portal session with Stripe.', color: 'danger', duration: 5000 }));
			// return dispatch(getCurrentTeamError(error));
			return error;
		},
	});
};


export const getCurrentTeam = (teamId) => (dispatch, getState) => {
	dispatch(getCurrentTeamRequest());
	return get({
		url: `https://api.socialseen.io/v1/teams/${teamId}`,
		onSuccess: (result) => {
			return dispatch(getCurrentTeamSuccess(result));
		},
		onError: (error) => {
			dispatch(showToast({ message: 'Oops, we couldn\'t find that team.', color: 'danger', duration: 5000 }));
			return dispatch(getCurrentTeamError(error));
		},
	});
};

const getCurrentTeamRequest = () => ({
	type: GET_CURRENT_TEAM_REQUEST,
	payload: {
		isLoading: true,
		data: null,
	},
});

const getCurrentTeamSuccess = (data) => ({
	type: GET_CURRENT_TEAM_SUCCESS,
	payload: {
		isLoading: false,
		data,
		error: null,
	},
});

const getCurrentTeamError = (error) => ({
	type: GET_CURRENT_TEAM_ERROR,
	payload: {
		isLoading: false,
		data: null,
		error: error,
	},
});

const currentTeamReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CURRENT_TEAM_REQUEST:
		case GET_CURRENT_TEAM_SUCCESS:
		case GET_CURRENT_TEAM_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default currentTeamReducer;
