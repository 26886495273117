// import { resetMacroConfig } from 'reducers/currentMacroConfig';

export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';

export const DEFAULT_TEMPLATE_ID = 4; //instagram 300x250

const initialState = {
	template: null,
};

export const setSelectedTemplate = (templateId) => (dispatch, getState) => {
	if (!templateId) {
		dispatch(setCurrentTemplate(null));
	} else {
		const state = getState();
		const templates = state.templates?.data?.creative_templates || [];
		const template = templates.find(t => t.idcreative_template === parseInt(templateId)) || null;
		dispatch(setCurrentTemplate(template));
	}
	// dispatch(resetMacroConfig());
};

const setCurrentTemplate = (template) => ({
	type: SET_CURRENT_TEMPLATE,
	payload: {
		template: template,
	},
});


const currentTemplateReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_TEMPLATE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default currentTemplateReducer;
