const NothingFound = ({ text, children }) => {
	let contents = 'Nothing found';

	if (children) {
		contents = children; 
	} else if (text) {
		contents = text;
	}
	return (
		<div className="nothing-found">
			<div className="text-center">
				{contents}
			</div>
		</div>
	);
};

export default NothingFound;
