import { getFacebookAuthUrl, getInstagramAuthUrl } from 'utils/authUtils';
import SocialIcon from 'components/SocialIcon';

// import './SocialConnectButton.css';

export const SocialConnectButton = ({ team_uuid, platform, className, text, color }) => {

	const connectFB = () => {
		let url = getFacebookAuthUrl(team_uuid);
		console.log('url', url);
		window.location.href = url;
	}
	
	const connectInsta = () => {
		let url = getInstagramAuthUrl(team_uuid);
		console.log('url', url);
		window.location.href = url;
	}

	const fb = (
		<button onClick={connectFB} className={`btn btn-sm btn-${color || 'primary'} me-2 ${className || ''}`}>
			<SocialIcon platform="facebook" className="me-1" /> {text || 'Connect with Facebook'}
		</button>
	);

	const insta = (
		<button onClick={connectInsta} className={`btn btn-sm btn-${color || 'primary'} me-2 ${className || ''}`}>
			<SocialIcon platform="instagram" className="me-1" /> {text || 'Connect with Instagram'}
		</button>
	);

	if (/instagram/gi.test(platform)) {
		return insta
	} else if (/facebook/gi.test(platform)) {
		return fb;
	}
	return null;
}

export default SocialConnectButton;