import { post, get } from 'data/services/apiService';
import { showToast } from 'reducers/toast';
import { getMacrosForApi } from 'selectors/macros';

export const GET_CREATIVES_REQUEST = 'GET_CREATIVES_REQUEST';
export const GET_CREATIVES_SUCCESS = 'GET_CREATIVES_SUCCESS';
export const GET_CREATIVES_ERROR = 'GET_CREATIVES_ERROR';

export const SAVE_CREATIVE_REQUEST = 'SAVE_CREATIVE_REQUEST';
export const SAVE_CREATIVE_SUCCESS = 'SAVE_CREATIVE_SUCCESS';
export const SAVE_CREATIVE_ERROR = 'SAVE_CREATIVE_ERROR';


const initialState = {
	isLoading: false,
	socialAccountId: null,
	data: null,
	error: null,
};

export const saveCreative = (postId) => (dispatch, getState) => {
	const state = getState();
	const socialAccountId = state.currentSocialAccount.socialAccount.social_account_id;
	const url = `https://api.socialseen.io/v1/social-accounts/${socialAccountId}/creative`;
	const data = {
		team_uuid: state.currentTeam.data.team_uuid,
		post_id: postId,
		creative_config: {
			creative_template_id: state.currentTemplate.template.idcreative_template,
			macros: getMacrosForApi(state),
		}
	};

	dispatch(saveCreativeRequest());


	console.log('data', data)
	return post({
		url,
		data,
		onSuccess: (result) => {
			console.log('result', result)
			dispatch(showToast({ message: 'Creative created.', color: 'success', duration: 5000 }));
			return dispatch(saveCreativeSuccess(result));
		},
		onError: (error) => {
			console.log('error', error)
			return dispatch(saveCreativeError(error));
		},
	});
};

export const getCreatives = (team_uuid) => (dispatch, getState) => {
	// const state = getState();
	let url = `https://api.socialseen.io/v1/teams/${team_uuid}/creative`;

	dispatch(getCreativesRequest());

	return get({
		url,
		onSuccess: (result) => {
			return dispatch(getCreativesSuccess(result));
		},
		onError: (error) => {
			dispatch(showToast({ message: 'Something went wrong loading creatives.', color: 'danger', duration: 5000 }));
			return dispatch(getCreativesError(error));
		},
	});
};

const getCreativesRequest = () => ({
		type: GET_CREATIVES_REQUEST,
		payload: {
			isLoading: true,
		},
});

const getCreativesSuccess = (data) => ({
		type: GET_CREATIVES_SUCCESS,
		payload: {
			isLoading: false,
			data,
			error: null,
		},
});

const getCreativesError = (error) => ({
		type: GET_CREATIVES_ERROR,
		payload: {
			isLoading: false,
			data: null,
			error: error,
		},
});

const saveCreativeRequest = (socialAccountId) => ({
		type: SAVE_CREATIVE_REQUEST,
		payload: {
			isLoading: true,
		},
});

const saveCreativeSuccess = (data) => ({
		type: SAVE_CREATIVE_SUCCESS,
		payload: {
			isLoading: false,
			data,
			error: null,
		},
});

const saveCreativeError = (error) => ({
		type: SAVE_CREATIVE_ERROR,
		payload: {
			isLoading: false,
			error: error,
		},
});


const previewLinksReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CREATIVES_REQUEST:
		case GET_CREATIVES_SUCCESS:
		case GET_CREATIVES_ERROR:
		case SAVE_CREATIVE_REQUEST:
		case SAVE_CREATIVE_SUCCESS:
		case SAVE_CREATIVE_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default previewLinksReducer;
