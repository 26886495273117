import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { closeModal } from 'reducers/modal';
import { SpinnerOverlay } from 'components/Spinner';

const StandardModal = (props) => {
	const dispatch = useDispatch();
	const modalRef = useRef(null);
	const {
		children,
		title,
		onConfirm,
		confirmLabel,
		className,
		size,
		closeOnOutsideClick,
		footerButtons,
		closeLabel,
		disableConfirm,
		disableClose,
		isLoading,
		isSaving,
	} = props;

	const onClose = () => {
		dispatch(closeModal());
	}

	let dialogClass = 'modal-dialog';

	if (size === 'sm' || size === 'lg' || size === 'xl') {
		dialogClass = `modal-dialog modal-${size}`;
	}

	const handleOutsideClick = (event) => {
		if (closeOnOutsideClick && event.target === modalRef.current) {
			//if the user clicks on the overlay outside the modal dialog, close the modal
			onClose();
		}
	}

	return  (
		<div className={`modal ${className || ''}`} tabIndex="-1" ref={modalRef} role="dialog" onClick={handleOutsideClick}>
		  <div className={dialogClass} role="document">
		    <div className="modal-content">
		      <div className="modal-header">
		        <h5 className="modal-title">{title || ''}</h5>
		        <button type="button" className="close" onClick={onClose} aria-label="Close" disabled={isSaving}>
		          <span aria-hidden="true"><i className="bi bi-x-lg" /></span>
		        </button>
		      </div>
		      <div className="modal-body">
	      		<SpinnerOverlay isLoading={isLoading || isSaving} />
		        {children}
		      </div>
		      <div className="modal-footer">
		        <button
		        	type="button"
		        	className="btn
		        	btn-secondary"
		        	disabled={disableClose === true || isSaving}
		        	onClick={onClose}
		        >
		        	{closeLabel || 'Close'}
		        </button>
		        {onConfirm && confirmLabel && 
		        	<button
		        		type="button"
		        		className="btn
		        		btn-primary"
		        		disabled={disableConfirm === true || isLoading || isSaving}
		        		onClick={() => onConfirm()}
		        	>
		        		{confirmLabel}
		        	</button>
		        }
		        {footerButtons}
		      </div>
		    </div>
		  </div>
		</div>
	);
};

export default StandardModal;
