import SocialConnectButton from 'components/SocialConnectButton';

import './NoSocialAccounts.css';

const NoSocialAccounts = ({ currentTeam }) => {
	return (
		<div className="no-social-accounts">
			<h1>Add a Social Account<br/>To Get Started</h1>

			{currentTeam?.team_uuid && (
				<div className="m-3">
					<SocialConnectButton team_uuid={currentTeam.team_uuid} platform="facebook" />
					<SocialConnectButton team_uuid={currentTeam.team_uuid} platform="instagram" />
				</div>
			)}

		</div>
	);
};

export default NoSocialAccounts;
