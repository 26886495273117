import { createSelector } from 'reselect';

const currentTeam = state => state.currentTeam?.data;
const currentUser = state => state.user?.userData;

const _getTeamSocialAccounts = (team) => {
	if (!team?.social_accounts?.facebook_pages && !team?.social_accounts?.instagram_accounts) {
		return [];
	}

	let fb = team?.social_accounts?.facebook_pages || [];
	let insta = team?.social_accounts?.instagram_accounts || [];

	return [].concat(fb).concat(insta);
}

const _getUserSocialAccounts = (user) => {
	if (!user?.social_accounts?.facebook_pages && !user?.social_accounts?.instagram_accounts) {
		return [];
	}

	let fb = user?.social_accounts?.facebook_pages || [];
	let insta = user?.social_accounts?.instagram_accounts || [];

	return [].concat(fb).concat(insta);
}

const _hasTeamSocials = (team) => {
	return team?.social_accounts?.facebook_pages?.length || team?.social_accounts?.instagram_accounts?.length;
}

const _hasUserSocials = (user) => {
	return user?.social_accounts?.facebook_pages?.length || user?.social_accounts?.instagram_accounts?.length;
}

export const getTeamSocialAccounts = createSelector([currentTeam], _getTeamSocialAccounts);
export const getUserSocialAccounts = createSelector([currentUser], _getUserSocialAccounts);
export const hasUserSocials = createSelector([currentUser], _hasUserSocials)
export const hasTeamSocials = createSelector([currentTeam], _hasTeamSocials)
