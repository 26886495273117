import AccountDropdown from 'components/appHeader/AccountDropdown';
import HeaderLogo from 'components/appHeader/HeaderLogo';
import HeaderNav from 'components/appHeader/HeaderNav';
// import TeamSelector from 'components/appHeader/TeamSelector';

import "./AppHeader.css";


const AppHeader = () => {
	return (
		<nav className="navbar sticky-top navbar-dark top-nav app-header">
			<div className="container-fluid">
				<div className="app-header-left">
					<HeaderLogo color="pink" />
					<HeaderNav />
				</div>
				<div className="app-header-right">
					{/*<div className="me-3"><TeamSelector /></div>*/}
					<AccountDropdown />
				</div>
			</div>
		</nav>
	);
};

export default AppHeader;
