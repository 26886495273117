import { useSelector } from 'react-redux';

import TestModal from 'modals/TestModal';
import CreateTeamModal from 'modals/CreateTeamModal';
import CreateAdModal from 'modals/CreateAdModal';
import MacroSettingsModal from 'modals/MacroSettingsModal';
import SocialConnectModal from 'modals/SocialConnectModal';
import SocialWizardModal from 'modals/SocialWizardModal';
import UserSettingsModal from 'modals/UserSettingsModal';

import 'modals/Modal.css';

export const MODAL_TYPES = {
	TEST_MODAL: 'TEST_MODAL',
	CREATE_TEAM: 'CREATE_TEAM',
	CREATE_AD: 'CREATE_AD',
	MACRO_SETTINGS: 'MACRO_SETTINGS',
	SELECT_TEAM: 'SELECT_TEAM',
	SOCIAL_CONNECT: 'SOCIAL_CONNECT',
	SOCIAL_WIZARD: 'SOCIAL_WIZARD',
	USER_SETTINGS: 'USER_SETTINGS',
};

const ModalManager = (props) => {
	const {
		currentModal,
		isOpen,
		modalOptions,
	} = useSelector(state => ({
		currentModal: state.modal.currentModal,
		isOpen: state.modal.isOpen,
		modalOptions: state.modal.modalOptions,
	}));

	if (isOpen && currentModal) {
		switch (currentModal) {
			case MODAL_TYPES.TEST_MODAL:
				return <TestModal options={modalOptions} />;
			case MODAL_TYPES.MACRO_SETTINGS:
				return <MacroSettingsModal options={modalOptions} />;
			case MODAL_TYPES.CREATE_TEAM:
				return <CreateTeamModal options={modalOptions} />;
			case MODAL_TYPES.CREATE_AD:
				return <CreateAdModal options={modalOptions} />;
			case MODAL_TYPES.SOCIAL_CONNECT:
				return <SocialConnectModal options={modalOptions} />;
			case MODAL_TYPES.SOCIAL_WIZARD:
				return <SocialWizardModal options={modalOptions} />;
			case MODAL_TYPES.USER_SETTINGS:
				return <UserSettingsModal options={modalOptions} />;
			default:
				return null
		}
	}

	return null;
};

export default ModalManager;
