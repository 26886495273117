import { useSelector, useDispatch } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { removeToast } from 'reducers/toast';

import './ToastManager.css';

const ToastManager = (props) => {
	const dispatch = useDispatch();
	const {
		toasts,
	} = useSelector(state => ({
		toasts: state.toast.toasts,
	}));

	const onRemove = (id) => {
		dispatch(removeToast(id));
	}
	
	if (toasts?.length) {
		return (
			<ToastContainer>
				{toasts.map(toast => (
					<Toast key={toast.id} onClose={() => onRemove(toast.id)} bg={toast.color} animation={true}>
						<Toast.Header>
							<strong className="me-auto">{toast.header}</strong>
						</Toast.Header>
						<Toast.Body>
							{toast.message}
						</Toast.Body>
					</Toast>
				))}
			</ToastContainer>
		);
	}

	return null;
};

export default ToastManager;
