import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'reducers/modal';
import { saveCreative } from 'reducers/creatives';
import { getTeamSocialAccounts } from 'selectors/socialAccounts';
import NoSocialAccounts from 'pages/social/NoSocialAccounts';
// import AdBuilderHeader from 'pages/adBuilder/AdBuilderHeader';
import AdBuilderSidebar from 'pages/adBuilder/AdBuilderSidebar';
import SelectAccount from 'pages/adBuilder/SelectAccount';
import { MODAL_TYPES } from 'view/ModalManager';
import { SpinnerOverlay } from 'components/Spinner';
import NothingFound from 'components/NothingFound';
import SocialPostCard from 'components/socialPosts/SocialPostCard';

// import Finsta from 'view/ads/new_insta_300x250';


import './AdBuilder.css';

const PostWrapper = ({ children, post, socialAccountId }) => {
	const dispatch = useDispatch();
	const createAd = () => {
		dispatch(openModal(MODAL_TYPES.CREATE_AD, { post, socialAccountId }));
	};

	return (
		<div className="create-ad-from-post">
			<div>{children}</div>
			<div className="p-2">
				<button className="btn btn-primary" onClick={createAd}>
					Create
				</button>
			</div>
		</div>
	);
};

const AdBuilder = () => {
	const dispatch = useDispatch();
	const {
		isLoading,
		currentTeam,
		socialAccounts,
		posts,
		previewLinks,
		currentSocialAccount,
		currentTemplate,
	} = useSelector(state => ({
		isLoading: state.currentTeam.isLoading || state.socialFeed.isLoading || state.templates.isLoading || state.previewLinks.isLoading || state.creatives.isLoading,
		currentTeam: state.currentTeam?.data || {},
		socialAccounts: getTeamSocialAccounts(state),
		posts: state.socialFeed.data?.feed?.data || [],
		previewLinks: state.previewLinks?.data?.feed?.data || [],
		currentSocialAccount: state.currentSocialAccount.socialAccount,
		currentTemplate: state.currentTemplate.template,
	}));

	let [isGridMode, setIsGridMode] = useState(false);

	if (!socialAccounts.length) {
		return <NoSocialAccounts currentTeam={currentTeam} />;	
	}

	let iframes = null;
	if (currentSocialAccount && currentTemplate) {
		iframes = (
			<div>
				{previewLinks.map((link, i) => (
					<div key={`${currentSocialAccount?.social_account_id}-${currentTemplate.idcreative_template}-${i}`} className="post-wrapper">
						<iframe
							width={currentTemplate.width}
							height={currentTemplate.height}
							className=""
							title={link.url}
							
							src={`https://api.socialseen.io/v1${link.url}`}
						/>
						<div>
							<button className="btn btn-sm btn-primary" onClick={() => dispatch(saveCreative(link.post_id))}>Create Ad</button>
						</div>
					</div>
				))}
			</div>
		);
	}
	let postEl = null;
	if (currentSocialAccount) {
		postEl = (
			<div>
				{posts.map((post, i) => (
					<PostWrapper key={i} post={post} socialAccountId={currentSocialAccount?.social_account_id}>
						<SocialPostCard platform={currentSocialAccount?.platform_type} post={post} />
					</PostWrapper>
				))}
			</div>
		);
	}

	let content = iframes || postEl;

	if (isGridMode && currentSocialAccount) {
		content = (
			<table className="table">
				<thead>
					<tr>
						<th>ID</th>
						<th>Placeholder</th>
						<th>Placeholder</th>
					</tr>
				</thead>
				<tbody>
					{posts.map((post, i) => (
						<tr key={`${currentSocialAccount?.social_account_id}-${i}`}>
							<td>{post.id}</td>
							<td>Placeholder</td>
							<td>Placeholder</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}

	const toggleIsGridMode = () => {
		setIsGridMode(!isGridMode);
	}

	// const finstaProps = {
	// 	AVATAR_URL: 'https://scontent-lga3-2.xx.fbcdn.net/v/t51.2885-15/315106187_6031779513512770_2439224753347678989_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=86c713&_nc_ohc=u85tossa0ZwAX__HAY3&_nc_ht=scontent-lga3-2.xx&edm=AL-3X8kEAAAA&oh=00_AfDOx0lqY54PVZ-yGEA8U4alApTgmXqGuIs1hTYjHRmTLw&oe=63F84015',
	// 	INSTA_USERNAME: 'Fazzion',
	// 	NUM_FOLLOWERS: '663',
	// 	MEDIA_URL: 'https://scontent-lga3-2.cdninstagram.com/v/t51.29350-15/318021988_547894323478357_3830025269953696669_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=75bUBoiTzzQAX_D28Hf&_nc_ht=scontent-lga3-2.cdninstagram.com&edm=AM6HXa8EAAAA&oh=00_AfAko20qhtTqli1TlCuctQrueUpAnrJ2CNmchjSWXjDmaA&oe=63F86BF7',
	// 	NUM_LIKES: '134',
	// 	NUM_COMMENTS: '12',
	// 	CTA_TEXT: 'Try now!',
	// };

	return (
		<div className="ad-builder-page">
			{/*<AdBuilderHeader toggleIsGridMode={toggleIsGridMode} isGridMode={isGridMode} />*/}
			<AdBuilderSidebar toggleIsGridMode={toggleIsGridMode} isGridMode={isGridMode} />
			<SpinnerOverlay isLoading={isLoading} />
			{/*<Finsta {...finstaProps} />*/}
			<div className="post-list">
				{currentSocialAccount && !posts?.length && !isLoading && <NothingFound />}
				{!currentSocialAccount && <SelectAccount />}
				{currentSocialAccount && content}
			</div>
		</div>
	);
};

export default AdBuilder;
