import { Link } from "react-router-dom";

import './SelectList.css';

let noop = () => {};

const SelectListRow = ({ label, onClick, url }) => {
	onClick = onClick || noop;
	return (
		<div className="select-list-row">
			{ url ? 
				<Link className="select-list-row-link" to={url}>{label}</Link> :
				<button className="select-list-row-button" onClick={onClick}>{label}</button>
			}
		</div>
	);
}

const SelectList = ({ items }) => {
	return (
		<div className="select-list">
			{items.map((item, i) => (
				<SelectListRow label={item.label} onClick={item.onClick} url={item.url} key={`${item.label}-${i}`} />
			))}
		</div>
	);
};

export default SelectList;
