const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const initialState = {
	currentModal: null,
	isOpen: false,
	modalOptions: {},
};

export const openModal = (modalName, options = {}) => ({
	type: OPEN_MODAL,
	payload: {
		currentModal: modalName,
		isOpen: true,
		modalOptions: options,
	}
});

export const closeModal = () => ({
	type: CLOSE_MODAL,
	payload: {
		currentModal: null,
		isOpen: false,
		modalOptions: {},
	}
});

const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_MODAL:
		case CLOSE_MODAL:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default modalReducer;
