import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import InputGroup from 'react-bootstrap/InputGroup';

import './FormComponents.css';

export const Input = ({ name, label, value, placeholder, onChange, type, disabled, isFloatingLabel }) => {
	let input = (
		<Form.Control
			type={type || 'text'}
			placeholder={placeholder || ''}
			name={name}
			onChange={onChange}
			value={value}
			autoComplete="off"
			disabled={disabled}
		/>
	);
	if (isFloatingLabel) {
		return <FloatingLabel className="soc-floating-input" label={label}>{input}</FloatingLabel>
	}
	return (
		<Form.Group className="mb-3">
			{label && <Form.Label>{label}</Form.Label>}
			{input}
		</Form.Group>
	);;
}

export const InputWithButton = ({ label, placeholder, onSubmit, disabled, buttonLabel, value, onChange }) => {
	const onKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSubmit();
		}
	}
	return (
		<Form.Group className="mb-3">
			{label && <Form.Label>{label}</Form.Label>} 
			<InputGroup className="mb-3">
			  <Form.Control
					type={'text'}
					placeholder={placeholder || ''}
					onChange={onChange}
					value={value}
					autoComplete="off"
					disabled={disabled}
					onKeyPress={onKeyPress}
			  />
			  <Button variant="primary" onClick={onSubmit} disabled={disabled}>{buttonLabel}</Button>
			</InputGroup>
		</Form.Group>

		// <div className="input-group mb-3">
		// 	<input type="text" className="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2">
		// 	<button className="btn btn-outline-secondary" type="button" id="button-addon2">Button</button>
		// </div>
	);

}


export const SubmitButton = ({ children, label, onSubmit, disabled }) => {
	return (
		<Form.Group className="mb-3">
			<Button variant="primary" onClick={onSubmit} disabled={disabled}>
				{children || label || "Submit"}
			</Button>
		</Form.Group>
	);
}

export const Select = ({ label, options, onChange, disabled, className, defaultValue, isFloatingLabel }) => {
	let select = (
    <Form.Select id={label} aria-label={label} onChange={onChange} disabled={disabled} className={className || ''} defaultValue={defaultValue} >
    	{options.map(option => (
    		<option value={option.value} key={option.value}>{option.label}</option>
    	))}
    </Form.Select>
  );
	if (isFloatingLabel) {
		return <FloatingLabel className="soc-floating-select" label={label}>{select}</FloatingLabel>
	}
  return select;
}