import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getTeams } from 'reducers/teams';
import { SpinnerOverlay } from 'components/Spinner';
import SelectList from 'components/SelectList';
import { openModal } from 'reducers/modal';
import { MODAL_TYPES } from 'view/ModalManager';

import './Teams.css';

const Teams = () => {
	const dispatch = useDispatch();
	const {
		isLoading,
		teams,
	} = useSelector(state => ({
		isLoading: state.teams.isLoading,
		teams: state.teams.teamList,
	}));

	useEffect(() => {
		// dispatch(getTeams());
	}, [dispatch]);

	const onCreateTeam = () => {
		dispatch(openModal(MODAL_TYPES.CREATE_TEAM, {}));
	}

	return (
		<div className="teams-page">
			<SpinnerOverlay isLoading={isLoading} />
			<div className="card">
				<div className="card-header">
					Teams
				</div>
				<div className="card-body">
					<SelectList items={teams.map(team => ({
						label: team.team_name,
						url: `/teams/${team.team_uuid}/ad-builder`
					}))} />
					<div className="new-team">
						<button onClick={onCreateTeam} className="btn btn-primary">
							Create Team
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Teams;
