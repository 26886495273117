import { post } from 'data/services/apiService';
import { showToast } from 'reducers/toast';
import { getMacrosForApi } from 'selectors/macros';

export const GET_PREVIEW_LINKS_REQUEST = 'GET_PREVIEW_LINKS_REQUEST';
export const GET_PREVIEW_LINKS_SUCCESS = 'GET_PREVIEW_LINKS_SUCCESS';
export const GET_PREVIEW_LINKS_ERROR = 'GET_PREVIEW_LINKS_ERROR';


const initialState = {
	isLoading: false,
	socialAccountId: null,
	data: null,
	error: null,
};

export const getPreviewLinks = (socialAccountId, templateId) => (dispatch, getState) => {
	let url = `https://api.socialseen.io/v1/social-accounts/${socialAccountId}/get-preview-links`;
	let macros = getMacrosForApi(getState());
	let data = {
		"creative_config": {
			"creative_template_id": templateId,

			"macros": macros,
			// "clickthrough1": "https://google.com",
			// "clickthrough2": "https://google.com",
		},
		// "feed_config": {
		// 	"filters": {
		// 		"include": {
		// 			"post_type": "VIDEO"
		// 		}
		// 	},
		// 	"order": [{
		// 		"like_count": "DESC"
		// 	}]
		// }
	};

	dispatch(getPreviewLinksRequest(socialAccountId));

	return post({
		url,
		data,
		onSuccess: (result) => {
			return dispatch(getPreviewLinksSuccess(result));
		},
		onError: (error) => {
			dispatch(showToast({ message: 'Something went wrong loading creative previews.', color: 'danger', duration: 5000 }));
			return dispatch(getPreviewLinksError(error));
		},
	});
};

const getPreviewLinksRequest = (socialAccountId) => ({
		type: GET_PREVIEW_LINKS_REQUEST,
		payload: {
			socialAccountId,
			isLoading: true,
		},
});

const getPreviewLinksSuccess = (data) => ({
		type: GET_PREVIEW_LINKS_SUCCESS,
		payload: {
			isLoading: false,
			data,
			error: null,
		},
});

const getPreviewLinksError = (error) => ({
		type: GET_PREVIEW_LINKS_ERROR,
		payload: {
			isLoading: false,
			data: null,
			error: error,
		},
});


const previewLinksReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PREVIEW_LINKS_REQUEST:
		case GET_PREVIEW_LINKS_SUCCESS:
		case GET_PREVIEW_LINKS_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default previewLinksReducer;
