import { createSelector } from 'reselect';

const currentTemplate = state => state.currentTemplate?.template;
const currentMacroConfig = state => state.currentMacroConfig?.config;

const _getUserInputMacros = (template) => {
	if (!template) {
		return [];
	}
	return template.macros?.filter(macro => macro.data_source === 'user_input') || [];
}

const _getMacrosForApi = (config, template) => {
	if (!config) {
		return [];
	}
	// [{
	// 	"macro": "CTA_TEXT",
	// 	"value": "Shop Now"
	// }]
	let templateMacros = {};
	template.macros.forEach(macro => {
		templateMacros[macro.macro] = true;
	});

	let macros = Object.keys(config)
		.filter(macroName => templateMacros[macroName])
		.map(macroName => ({
			macro: macroName,
			value: config[macroName],
		}));
	return macros;
}

export const getUserInputMacros = createSelector([currentTemplate], _getUserInputMacros);
export const getMacrosForApi = createSelector([currentMacroConfig, currentTemplate], _getMacrosForApi);
