const SHOW_TOAST = 'SHOW_TOAST';
const REMOVE_TOAST = 'REMOVE_TOAST';

const initialState = {
	toasts: [],
};


export const showToast = ({ message, header, color, duration }) => (dispatch, getState) => {
	const state = getState();
	let toasts = state.toast.toasts;
	let headerText = header || 'Notice';

	if (!header && color === 'danger') {
		headerText = 'Error';
	} else if (!header && color === 'success') {
		headerText = 'Success';
	} else if (!header && color === 'warning') {
		headerText = 'Warning';
	}

	if (!message) {
		return;
	}

	let newToast = {
		id: Math.random(),
		message,
		header: headerText,
	};

	if (color) {
		newToast.color = color;
	}

	toasts.push(newToast);

	if (duration && typeof duration === 'number') {
		setTimeout(() => {
			dispatch(removeToast(newToast.id));
		}, duration);
	}


	return dispatch({
		type: SHOW_TOAST,
		payload: {
			toasts,
		},
	});
};



export const removeToast = (toastId) => (dispatch, getState) => {
	const state = getState();
	let toasts = state.toast.toasts.concat([]);
	let newToasts = toasts.filter(toast => toast.id !== toastId);

	return dispatch({
		type: REMOVE_TOAST,
		payload: {
			toasts: newToasts,
		},
	});
};

const toastReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_TOAST:
		case REMOVE_TOAST:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default toastReducer;
