// import Card from 'react-bootstrap/Card';
// import Placeholder from 'react-bootstrap/Placeholder';

import InstagramPost from 'view/components/socialPosts/InstagramPost';
import FacebookPost from 'view/components/socialPosts/FacebookPost';

import './SocialPostCard.css';

export const SocialPostCard = ({ platform, post }) => {
	let contents = null;

	if (platform === 'INSTAGRAM_STANDALONE' || platform === 'INSTAGRAM_BUSINESS') {
		contents = <InstagramPost post={post} />;
	} else if (platform === 'FACEBOOK') {
		contents = <FacebookPost post={post} />;
	}

	return (
		<div className="social-post-card-wrapper">
			<div className="social-post-card">
				{contents}
			</div>
		</div>
	);
}

export default SocialPostCard;