import './MissingMedia.css';

export const MissingMedia = ({ text, height, isSquare }) => {
	let style = {};

	if (height) {
		style.height = height;
	}

	return (
		<div className={`missing-media ${isSquare ? 'is-square' : ''}`} >
			{text || "Media not found"}
		</div>
	);
}

export default MissingMedia;