import { get, post, del } from 'data/services/apiService';
import { showToast } from 'reducers/toast';
import { getCurrentTeam } from 'reducers/currentTeam';

export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_ERROR = 'GET_TEAMS_ERROR';

export const POST_TEAM_REQUEST = 'POST_TEAM_REQUEST';
export const POST_TEAM_SUCCESS = 'POST_TEAM_SUCCESS';
export const POST_TEAM_ERROR = 'POST_TEAM_ERROR';


const initialState = {
	isLoading: false,
	isSaving: false,
	teamList: [],
	error: null,
};


export const addUsersToTeam = (teamId, userEmails=[]) => (dispatch, getState) => {
	//schema = [{ email: 'alice@example.com' }, { email: 'bob@example.com' }]
	let state = getState();
	let currentTeam = state.currentTeam.data;
	const data = {
		users: userEmails.map(email => ({ email })),
	};

	dispatch(postTeamRequest());

	return post({
		url: `https://api.socialseen.io/v1/teams/${teamId}/users`,
		data,
		onSuccess: (result) => {
			console.log('addUsersToTeam result', result)
			if (teamId === currentTeam.team_uuid) {
				dispatch(getCurrentTeam(teamId));
			}
			return dispatch(postTeamSuccess());
		},
		onError: (error) => {
			console.log('addUsersToTeam error', error)
			return dispatch(postTeamError(error));
		},
	});
}

export const removeUserFromTeam = (teamId, user) => (dispatch, getState) => {
	dispatch(postTeamRequest());

	return del({
		url: `https://api.socialseen.io/v1/teams/${teamId}/users/${user.username}`,
		onSuccess: (result) => {
			console.log('result', result)
			return dispatch(postTeamSuccess(result));
		},
		onError: (error) => {
			console.log('error', error)
			return dispatch(postTeamError(error));
		},
	});
}

export const getTeams = (params) => (dispatch, getState) => {
	const url = `https://api.socialseen.io/v1/teams`;

	dispatch(getTeamsRequest());

	return get({
		url,
		onSuccess: (result) => {
			console.log('get teams res', result)
			return dispatch(getTeamsSuccess(result));
		},
		onError: (error) => {
			console.log('get teams error', error);
			dispatch(showToast({ message: 'Oops, looks like something went wrong loading your teams.', color: 'danger', duration: 5000 }));
			return dispatch(getTeamsError(error));
		},
	});
};

export const createTeam = (teamData) => (dispatch) => {
	const url = `https://api.socialseen.io/v1/teams`;
	const data = {
		team_name: teamData.teamName,
	};

	dispatch(postTeamRequest());

	return post({
		url,
		data,
		onSuccess: (result) => {
			console.log('result', result)
			return dispatch(postTeamSuccess(result));
		},
		onError: (error) => {
			console.log('error', error)
			return dispatch(postTeamError(error));
		},
	});
};

export const addSocialAccountToTeam = (team_uuid, socialAccountId) => (dispatch) => {
	const url = `https://api.socialseen.io/v1/teams/${team_uuid}/social-accounts`;
	const data = {
		social_accounts: [{
			social_account_id: socialAccountId,
		}],
	};

	if (!team_uuid || !socialAccountId) {
		console.log('error connecting social account to team. team_uuid', team_uuid, 'socialAccountId', socialAccountId);
		return;
	}

	dispatch(postTeamRequest());

	return post({
		url,
		data,
		onSuccess: (result) => {
			console.log('result', result)
			return dispatch(updateTeamSuccess(result));
		},
		onError: (error) => {
			console.log('error', error)
			return dispatch(postTeamError(error));
		},
	});
};


// export const saveFacebookToken = (team_uuid, facebookAuthObject) => (dispatch) => {
// 	const url = `https://api.socialseen.io/v1/teams/${team_uuid}/add-facebook-pages`;
// 	const data = facebookAuthObject;

// 	if (!team_uuid || !facebookAuthObject) {
// 		console.log('error saving facebook token. team_uuid', team_uuid, 'facebookAuthObject', facebookAuthObject);
// 		return;
// 	}

// 	dispatch(postTeamRequest());

// 	return post({
// 		url,
// 		data,
// 		onSuccess: (result) => {
// 			console.log('result', result)
// 			return dispatch(updateTeamSuccess(result));
// 		},
// 		onError: (error) => {
// 			console.log('error', error)
// 			return dispatch(postTeamError(error));
// 		},
// 	});
// };

// export const saveInstagramToken = (team_uuid, instagramAuthObject) => (dispatch) => {
// 	const url = `https://api.socialseen.io/v1/teams/${team_uuid}/add-instagram-account`;
// 	const data = instagramAuthObject;

// 	if (!team_uuid || !instagramAuthObject) {
// 		console.log('error saving facebook token. team_uuid', team_uuid, 'instagramAuthObject', instagramAuthObject);
// 		return;
// 	}

// 	dispatch(postTeamRequest());

// 	return post({
// 		url,
// 		data,
// 		onSuccess: (result) => {
// 			console.log('result', result)
// 			return dispatch(updateTeamSuccess(result));
// 		},
// 		onError: (error) => {
// 			console.log('error', error)
// 			return dispatch(postTeamError(error));
// 		},
// 	});
// };

const getTeamsRequest = () => ({
		type: GET_TEAMS_REQUEST,
		payload: {
			isLoading: true,
		},
});

export const getTeamsSuccess = (teamList) => ({
		type: GET_TEAMS_SUCCESS,
		payload: {
			isLoading: false,
			teamList,
			error: null,
		},
});

const getTeamsError = (error) => ({
		type: GET_TEAMS_ERROR,
		payload: {
			isLoading: false,
			teamList: [],
			error: error,
		},
});

const postTeamRequest = () => ({
		type: POST_TEAM_REQUEST,
		payload: {
			isSaving: true,
		},
});

const postTeamSuccess = (newTeam) => (dispatch, getState) => {
	const state = getState();
	let teams = [...state.teams.teamList];

	if (newTeam) {
		teams.push(newTeam);
	}

	return dispatch({
		type: POST_TEAM_SUCCESS,
		payload: {
			isSaving: false,
			teamList: teams,
			error: null,
		},
	})
};

const postTeamError = (error) => ({
		type: POST_TEAM_ERROR,
		payload: {
			isSaving: false,
			error: error,
		},
});

export const updateTeamSuccess = (updatedTeam) => (dispatch, getState) => {
	const state = getState();
	let teams = [...state.teams.teamList];
	let index = teams.findIndex(team => team.team_uuid === updatedTeam.team_uuid);
	teams[index] = updatedTeam;

	return dispatch({
		type: POST_TEAM_SUCCESS, //does this need its own type? they're all the same...
		payload: {
			isSaving: false,
			teamList: teams,
			error: null,
		},
	})
};


const teamsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_TEAMS_REQUEST:
		case GET_TEAMS_SUCCESS:
		case GET_TEAMS_ERROR:
		case POST_TEAM_REQUEST:
		case POST_TEAM_SUCCESS:
		case POST_TEAM_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default teamsReducer;
