// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';


import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Input/*, SubmitButton*/ } from 'components/FormComponents';

import { setMacro } from 'reducers/currentMacroConfig';
import { getUserInputMacros } from 'selectors/macros';
import { getPreviewLinks } from 'reducers/previewLinks';
import { setSelectedTemplate, DEFAULT_TEMPLATE_ID } from 'reducers/currentTemplate';
import { setSelectedSocialAccount } from 'reducers/currentSocialAccount';
import { getFeed } from 'reducers/socialFeed';
import { getTeamSocialAccounts } from 'selectors/socialAccounts';

import { Select } from 'components/FormComponents';

// import { openModal } from 'reducers/modal';
// import { MODAL_TYPES } from 'view/ModalManager';

// import AccountDropdown from 'components/appHeader/AccountDropdown';

import './AdBuilderSidebar.css';

const AdBuilderSidebar = ({ children, toggleIsGridMode, isGridMode }) => {
	const dispatch = useDispatch();
	const {
		macros,
		macroConfig,
		currentSocialAccount,
		socialAccountId,
		templateId,
		templateOptions,
		accountOptions,
		currentTemplate,
	} = useSelector(state => ({
		macros: getUserInputMacros(state),
		macroConfig: state.currentMacroConfig.config,
		currentSocialAccount: state.currentSocialAccount.socialAccount,
		socialAccountId: state.currentSocialAccount.socialAccount?.social_account_id,
		templateId: state.currentTemplate.template?.idcreative_template,
		templateOptions: (state.templates.data?.creative_templates || []).map(template => ({
			value: template.idcreative_template,
			label: template.template_name
		})),
		accountOptions: [{ value: '', label: 'Select account'}]
			.concat(getTeamSocialAccounts(state).map(account => ({
				value: account.social_account_id,
				label: `@${account.external_handle} (${account.platform_name})`
			}))),
		currentTemplate: state.currentTemplate.template,
	}));

	// const openMacroModal = () => {
	// 	dispatch(openModal(MODAL_TYPES.MACRO_SETTINGS, {}));
	// }

	const onSelectTemplate = (templateId) => {
		dispatch(setSelectedTemplate(templateId));
		if (currentSocialAccount) {
			dispatch(getPreviewLinks(currentSocialAccount.social_account_id, templateId));
		}
	}

	const onSelectAccount = (accountId) => {
		if (accountId) {
			dispatch(setSelectedSocialAccount(accountId));
			dispatch(getFeed(accountId));	
			if (currentTemplate) {
				dispatch(getPreviewLinks(accountId, currentTemplate.idcreative_template));
			}
		} else {
			setSelectedSocialAccount(null);
		}
	}

	const onMacroChange = (name, val) => {
		dispatch(setMacro(name, val));
	}

	const updateLinks = () => {
		if (socialAccountId && templateId) {
			dispatch(getPreviewLinks(socialAccountId, templateId));
		}
	}

	return (
		<div className="sidebar ad-builder-sidebar px-1">
			<Form>
				<Row className="input-wrapper mx-2" xs="auto">
					<Select label="Social Account" isFloatingLabel className="form-select-sm" onChange={e => onSelectAccount(e.target.value)} options={accountOptions} />
				</Row>
				<Row className="input-wrapper mx-2" xs="auto">
					<Select label="Template" isFloatingLabel defaultValue={DEFAULT_TEMPLATE_ID} className="form-select-sm" onChange={e => onSelectTemplate(e.target.value)} options={templateOptions} />
				</Row>
				{macros.map(macro => (
					<Input key={macro.macro} isFloatingLabel label={macro.name} value={macroConfig[macro.macro] || ''} name={macro.macro} onChange={(e) => onMacroChange(macro.macro, e.target.value)} />
				))}
			</Form>

			<button className="btn btn-sm btn-primary ms-2" onClick={updateLinks} disabled={!socialAccountId || !templateId}>
				Update Previews
			</button>
{/*			<button className="btn btn-sm btn-primary ms-2" onClick={openMacroModal}>
				<i className="bi bi-gear" /> Configure Macros
			</button>*/}
		</div>
	);
};

export default AdBuilderSidebar;
