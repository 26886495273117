import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPreviewLinks } from 'reducers/previewLinks';
import StandardModal from 'modals/StandardModal';

const CreateAdModal = ({ options }) => {
	const dispatch = useDispatch();

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		dispatch(getPreviewLinks(options.socialAccountId, options.templateId))
	}, []);

	const {
		// isLoading,
		previewLinks,
	} = useSelector(state => ({
		// isLoading: state.previewLinks.isLoading,
		previewLinks: state.previewLinks?.data?.feed?.data || [],
	}));

	console.log('previewLinks', previewLinks)

	return (
		<StandardModal
			title="Create New Ad"
			size="md"
			closeOnOutsideClick={false}
		>
			<div>
				{previewLinks.length && <iframe title={previewLinks[0]?.url} src={`https://api.socialseen.io/v1${previewLinks[0]?.url}`} />}
			</div>
		</StandardModal>
	);
};

export default CreateAdModal;
