import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';

import { addUsersToTeam, removeUserFromTeam } from 'reducers/teams';

import { InputWithButton } from 'components/FormComponents';

// import './TeamUsers.css';

const TeamUsers = ({ options }) => {
	const dispatch = useDispatch();
	const {
		currentTeam,
		teamId,
	} = useSelector(state => ({
		currentTeam: state.currentTeam.data || {},
		teamId: state.currentTeam.data?.team_uuid,
	}));

	const [emailToAdd, setEmailToAdd] = useState('');

	const removeUser = (user) => {
		console.log('user to remove', user);
		dispatch(removeUserFromTeam(teamId, user));
	}

	const addUser = () => {
		dispatch(addUsersToTeam(teamId, [emailToAdd])).then(() => {
			setEmailToAdd('')
		});
	}

	return (
		<div className="form-page">
			<h4 className="mb-4">Team Collaborators</h4>
			<div className="mb-4">Team name: {currentTeam?.team_name}</div>
			<table className="table">
				<thead>
					<tr><th>Email</th><th>Role</th><th /></tr>
				</thead>
				<tbody>
					{currentTeam?.users?.map(user => (
						<tr key={user.username}><td>{user.email || '-'}</td><td>{user.user_role}</td><td><button onClick={() => removeUser(user)} className="btn btn-danger btn-sm">Remove</button></td></tr>
					))}
				</tbody>
			</table>
			<Form>
				<InputWithButton
					label="Add user"
					buttonLabel="Add"
					placeholder="Email"
					onChange={e => setEmailToAdd(e.target.value)}
					value={emailToAdd}
					onSubmit={addUser}
				/>
			</Form>
		</div>
	);
};

export default TeamUsers;
