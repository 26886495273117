// import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SocialIcon from 'components/SocialIcon';

import { addSocialAccountToTeam } from 'reducers/teams';
import { hasUserSocials, getUserSocialAccounts, getTeamSocialAccounts } from 'selectors/socialAccounts';

import StandardModal from 'modals/StandardModal';

import './SocialWizardModal.css';

const SocialWizardModal = ({ options }) => {
	const dispatch = useDispatch();

	const {
		isLoading,
		noSocials,
		isSaving,
		userData,
		userSocialAccounts,
		teamSocialAccounts,
		currentTeam,
	} = useSelector(state => ({
		isLoading: state.user.isLoading || state.teams.isLoading,
		noSocials: !hasUserSocials(state),
		isSaving: state.teams.isSaving,
		userData: state.user.userData || {},
		userSocialAccounts: getUserSocialAccounts(state),
		teamSocialAccounts: getTeamSocialAccounts(state),
		currentTeam: state.currentTeam.data || {},
	}));

	const connect = (socialAccountId) => {
		return dispatch(addSocialAccountToTeam(currentTeam.team_uuid, socialAccountId));
	}

	const isConnected = (userSocialAccount) => {
		return teamSocialAccounts.find(teamSocialAccount => teamSocialAccount.social_account_id === userSocialAccount.social_account_id)
	}

	return (
		<StandardModal
			title="Connect Social Accounts"
			size="lg"
			closeOnOutsideClick={false}
			isLoading={isLoading}
			isSaving={isSaving}
		>
			<div className="steps-header d-flex">
				<div className="step">Step 1</div>
				<div className="step">Step 2</div>
			</div>
			<div>
				<div className="mb-4">Authorized social accounts for <strong>{userData?.name} ({userData?.email})</strong></div>
				<div className="">
					<table className="table">
						<thead>
							<tr>
								<th />
								<th>@handle</th>
								<th>Account Name</th>
								<th>Platform</th>
								<th />
								<th />
							</tr>
						</thead>
						<tbody>
							{noSocials && <tr><td colSpan="5" className="text-center">No social accounts connected</td></tr>}
							{userSocialAccounts.map(account => (
								<tr key={account.external_account_id}>
									<td><SocialIcon platform={account.platform_type} autoColor /></td>
									<td>@{account.external_handle}</td>
									<td>{account.external_name}</td>
									<td>{account.platform_name}</td>
									<td>
										{isConnected(account) ? 
											<span className="text-success">Connected!</span>
										 :
											<button className="btn btn-sm btn-primary" onClick={() => connect(account.social_account_id)}>Connect</button>
										}
									</td>
									<td>
										<button className="btn btn-sm btn-danger" disabled onClick={() => {}}>Disconnect</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</StandardModal>
	);
};

export default SocialWizardModal;
