import { get } from 'data/services/apiService';
import { showToast } from 'reducers/toast';

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_ERROR = 'GET_TEMPLATES_ERROR';


const initialState = {
	isLoading: false,
	data: null,
	error: null,
};

export const getTemplates = (platformType) => (dispatch, getState) => {
	let url = `https://api.socialseen.io/v1/creative-templates/?platform_type=0`; //todo: remove platform_type when api supports

	dispatch(getTemplatesRequest());

	return get({
		url,
		onSuccess: (result) => {
			return dispatch(getTemplatesSuccess(result));
		},
		onError: (error) => {
			dispatch(showToast({ message: 'Something went wrong loading templates.', color: 'danger', duration: 5000 }));
			return dispatch(getTemplatesError(error));
		},
	});
};

const getTemplatesRequest = (socialAccountId) => ({
		type: GET_TEMPLATES_REQUEST,
		payload: {
			isLoading: true,
		},
});

const getTemplatesSuccess = (data) => ({
		type: GET_TEMPLATES_SUCCESS,
		payload: {
			isLoading: false,
			data,
			error: null,
		},
});

const getTemplatesError = (error) => ({
		type: GET_TEMPLATES_ERROR,
		payload: {
			isLoading: false,
			data: null,
			error: error,
		},
});


const templatesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_TEMPLATES_REQUEST:
		case GET_TEMPLATES_SUCCESS:
		case GET_TEMPLATES_ERROR:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default templatesReducer;
