import { default as BSpinner } from 'react-bootstrap/Spinner';

import './Spinner.css';

export const Spinner = ({ text }) => {
	return (
		<div className="spinner-container">
			<div className="spinner-wrapper">
				<BSpinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</BSpinner>
				{text && <div className="spinner-text">{text}</div>}
			</div>
		</div>
	);
}

export const SpinnerOverlay = ({ isLoading, text }) => {
	if (!isLoading) {
		return null;
	}

	return (
		<div className="spinner-overlay">
			<Spinner text={text} />
		</div>
	);
}

export default Spinner;