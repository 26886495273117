import { useSelector, useDispatch } from 'react-redux';

import { getPreviewLinks } from 'reducers/previewLinks';
import { setSelectedSocialAccount } from 'reducers/currentSocialAccount';
import { getFeed } from 'reducers/socialFeed';
import { getTeamSocialAccounts } from 'selectors/socialAccounts';

import { Select } from 'components/FormComponents';

import './SelectAccount.css';


const SelectAccount = ({ children, toggleIsGridMode, isGridMode }) => {
	const dispatch = useDispatch();
	const {
		accountOptions,
		currentTemplate,
	} = useSelector(state => ({
		accountOptions: [{ value: '', label: 'Select account'}]
			.concat(getTeamSocialAccounts(state).map(account => ({
				value: account.social_account_id,
				label: `@${account.external_handle} (${account.platform_name})`
			}))),
		currentTemplate: state.currentTemplate.template,
	}));

	const onSelectAccount = (accountId) => {
		if (accountId) {
			dispatch(setSelectedSocialAccount(accountId));
			dispatch(getFeed(accountId));	
			if (currentTemplate) {
				dispatch(getPreviewLinks(accountId, currentTemplate.idcreative_template));
			}
		} else {
			setSelectedSocialAccount(null);
		}
	}

	return (
		<div className="text-center select-account">
			<h3 className="mb-4">Select a Social Account</h3>
			<div>
				<Select
					label="Social Account"
					className="form-select-md"
					onChange={e => onSelectAccount(e.target.value)}
					options={accountOptions}
				/>
			</div>
		</div>
	);
};

export default SelectAccount;
