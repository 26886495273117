// import { useDispatch } from 'react-redux';

// import { openModal } from 'reducers/modal';
// import { MODAL_TYPES } from 'view/ModalManager';

import { useHistory } from "react-router-dom";

const MainPage = () => {
	const history = useHistory();
	history.replace(`/teams`);
	// const dispatch = useDispatch();

	// const openTestModal = () => {
	// 	dispatch(openModal(MODAL_TYPES.TEST_MODAL, {
	// 		customText: 'Custom text',
	// 	}));
	// }

	// const colors = [ 'primary', 'secondary', 'danger', 'success', 'warning', 'info', ]

	return (
		<div className="main-page">
			Welcome
{/*			<div className="m-3">
				Color test (test commit 2)
			</div>
			<div className="m-2 p-2">
				{colors.map(color => (
					<button className={`m-2 btn btn-${color}`} onClick={openTestModal}>
						{color} Button
					</button>
				))}
			</div>
			<div className="m-2 p-2">
				{colors.map(color => (
					<button disabled className={`m-2 btn btn-${color}`} onClick={openTestModal}>
						{color} Disabled
					</button>
				))}
			</div>
			<div className="m-2 p-2">
				{colors.map(color => (
					<div className={`m-2 badge bg-${color}`}>
						{color} Badge 123
					</div>
				))}
			</div>
			<div className="m-2 p-2">
				{colors.map(color => (
					<div className={`m-2 alert alert-${color}`}>
						<span className="me-2"><i className="bi bi-exclamation-circle-fill" /></span><span>An example <strong>{color} alert</strong> with an icon</span>
					</div>
				))}
			</div>*/}
		</div>
	);
};

export default MainPage;
