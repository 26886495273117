import { useSelector } from 'react-redux';

import HeaderNavLink from 'components/appHeader/HeaderNavLink';

const HeaderNav = () => {
	const {
		currentTeam,
	} = useSelector(state => ({
		currentTeam: state.currentTeam.data || {},
	}));

	const links = [
		{
			label: 'Ad Builder',
			url: `/teams/${currentTeam.team_uuid}/ad-builder`,
			icon: 'tools',
		},
		{
			label: 'Ad Library',
			url: `/teams/${currentTeam.team_uuid}/ad-library`,
			icon: 'folder2-open',
		},
		{
			label: 'Social Accounts',
			url: `/teams/${currentTeam.team_uuid}/social-accounts`,
			icon: 'person-badge',
		},
		// {
		// 	label: 'Team Settings',
		// 	url: `/teams/${currentTeam.team_uuid}/team-settings`,
		// 	icon: 'people',
		// },
	];

	return (
		<div className="header-nav">
			<div className="nav nav-pills flex-row mb-auto">
				{links.map(link => <HeaderNavLink {...link} key={link.url} />)}
			</div>
		</div>
	);
};

export default HeaderNav;